<template>
  <div>
    <!--begin::customer-->
    <h3 class="card-label">
      {{ $t('MENU.invoice_status')}}
      <span class="text-muted pt-2 font-size-sm d-block"></span>
    </h3>

    <div class="card card-custom">

      <div class="card-body">
            <div class="row mt-5">
              <div class="col-12">
                <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
                  <h6 class="my-auto text-white">{{$t('invoice_status.statuses')}}</h6>
                </div>
                <div class="table-responsive">
                  <table class="table table-row-bordered">
                    <thead>
                    <tr>
                      <th>{{$t('invoice_status.invoice')}}</th>
                      <th>{{$t('invoice_status.invoice_no')}}</th>
                      <th>{{$t('invoice_status.description')}}</th>
                      <th>{{$t('invoice_status.details')}}</th>
                    </tr>

                    </thead>
                    <tbody>
                    <template v-if="data.length > 0" >
                      <tr v-for="(row, index) in data" :key="index">

                        <td>{{row.key}}</td>
                        <td>{{row.subject_id}}</td>
                        <td>{{row.description}}</td>
                        <td>{{row.details}}</td>


                      </tr>
                    </template>
                    <tr v-else>
                      <td colspan="4">{{$t('invoice_status.no_data')}}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
      </div>
    </div>
    <!--end::customer-->
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
  name: "View-invoice-status",
  data() {
    return {
      mainRoute: '/base/activitylogs',
      id: this.$route.params.id? this.$route.params.id : null,
      type: this.$route.params.type? this.$route.params.type : 'SalesInvoice',
      data: {},


    };
  },
  methods: {
    async getData() {
      if (this.id)
        ApiService.get(`${this.mainRoute}/${this.type}/${this.id}`).then((response) => {
          this.data = response.data.data;
        });
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.sales_invoices_management"), route: '/sales/sales_invoices'}, {title: this.$t("MENU.invoice_status")}]);

    if (this.id) {
      this.getData();
    }

  },
}
</script>

<style scoped>

</style>